
import request from '@/utils/request'

// 账号列表
export function listBusinessAdmin (params) {
  return request.post('/api/listBusinessAdmin', params)
}

// 角色列表
export function businessAllRoleList (params) {
  return request.post('/api/businessAllRoleList', params)
}

// 角色状态为正常的角色
export function businessStatusRoleList (params) {
  return request.post('/api/businessStatusRoleList', params)
}

// 创建账号
export function createBusinessAdmin (params) {
  return request.post('/api/createBusinessAdmin', params)
}

// 编辑账号
export function editBusinessAdmin (params) {
  return request.post('/api/editBusinessAdmin', params)
}

// 删除账号
export function statusBusinessAdmin (params) {
  return request.post('/api/statusBusinessAdmin', params)
}
