<template>
  <div>
    <el-dialog
      :title="title"
      width="520px"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      custom-class="accountModal"
      :before-close="handleClose"
    >
      <el-form
        :model="form"
        ref="submitForm"
        size="small"
        :rules="rules"
        label-width="100px"
      >
        <el-form-item label="用户名：" prop="username">
          <el-input
            v-model="form.username"
            clearable
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="mobile">
          <el-input
            maxlength="11"
            v-model="form.mobile"
            clearable
            placeholder="请输入手机后"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码：" prop="password" v-if="type != 'edit'">
          <el-input
            v-model="form.password"
            clearable
            placeholder="请输入密码"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="状态：" prop="accountStatus" v-if="type == 'edit'">
          <el-radio-group v-model="form.accountStatus">
            <el-radio :label="1">正常</el-radio>
            <el-radio :label="2">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="角色：" prop="appointRoleIdList">
          <el-checkbox-group
            v-model="form.appointRoleIdList"
            @change="onRoleIdChange"
          >
            <el-checkbox
              v-for="item in allRoleList"
              :key="item.role_id"
              :label="item"
              name="type"
              >{{ item.role_name }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="一级机构：" v-if="showSenior">
          <span>{{ seniorList.name }}</span>
        </el-form-item>
        <el-form-item label="主体名称：" v-if="showEntity">
          <span>{{ entityList.name }}</span>
        </el-form-item>
        <el-form-item label="商家名称：" v-if="showBusiness">
          <el-select
            v-if="myBrand || myComp"
            style="width: 100%"
            v-model="form.businessId"
            :remote-method="businessRemoteMethod"
            :loading="businessLoading"
            placeholder="选择商家"
            clearable
            filterable
            remote
            @focus="businessRemoteMethod()"
          >
            <el-option
              v-for="item in businessList"
              :key="item.id"
              :label="item.full_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <span v-else>{{ myShop.name }}</span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close()" size="small">取 消</el-button>
        <el-button type="primary" @click="confirm('submitForm')" size="small"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { JSEncrypt } from "jsencrypt";
import {
  editBusinessAdmin,
  createBusinessAdmin,
} from "@/api/user.js";
import { getMyBrand, getMyComp, getMyShop } from "@/utils/index.js";
import { suggestBusinessList } from "@/api/business.js";
export default {
  name: "accountModal",

  data() {
    let checkPhone = (rule, value, callback) => {
      let reg = /^1[345789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入11位手机号"));
      } else {
        callback();
      }
    };
    return {
      myBrand: getMyBrand(),
      myComp: getMyComp(),
      myShop: getMyShop(),
      title: "新增账号",
      dialogFormVisible: false,
      editManagerId: "",
      form: {
        password: "",
        appointRoleIdList: [],
        businessId: "",
        seniorId: "",
        entityId: "",
        accountStatus: 1,
      },
      rules: {
        username: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入正确的手机号", trigger: "blur" },
          {
            type: "number",
            validator: checkPhone,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        appointRoleIdList: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个角色",
            trigger: "change",
          },
        ],

        accountStatus: [
          { required: true, message: "请选择账号状态", trigger: "change" },
        ],
      },
      allRoleList: [],
      allRoleId: [],
      type: "add",
      showBusiness: false,
      showSenior: false,
      showEntity: false,
      seniorList: {},
      entityList: {},
      businessLoading: false,
      businessList: [],
      baseBusinessList: [],
    };
  },

  mounted() {
  },

  methods: {
    async isShow(type, val,roleLists) {
      this.dialogFormVisible = true;
      this.type = type;
      this.allRoleList = roleLists
      console.log(roleLists)
      this.getRepaymentList();
      this.baseBusinessList = await this.getSelectBusiness();
      if (type == "edit") {
        this.title = "修改账号";
        this.editManagerId = val.id;
        var role_id = val.role_id.split(",");
        var roleList = [];
        var roleTypeList = [];
        role_id.map((item) => {
          this.allRoleList.map((vals) => {
            if (Number(item) == vals.role_id) {
              roleList.push(vals);
              roleTypeList.push(vals.role_type);
            }
          });
        });
        this.screenRoleType(roleTypeList);
        this.businessRemoteMethod(val.business_name);
        this.form = {
          editManagerId: val.id,
          appointRoleIdList: roleList,
          businessId: val.business_id || "",
          seniorId: val.senior_id || "",
          entityId: val.entity_id || "",
          accountStatus: val.status,
          mobile: val.mobile,
          username: val.username,
        };
      } else {
        this.title = "新增账号";
        this.editManagerId = ''
      }
    },
    async getRepaymentList() {
      this.seniorList = this.myBrand;
      this.entityList = this.myComp;
      console.log(this.myShop);
    },
    confirm(submitForm) {
      this.$refs[submitForm].validate(async (valid) => {
        if (valid) {
          this.allRoleId = [];
          this.form.appointRoleIdList.map((item) => {
            this.allRoleId.push(item.role_id);
          });
          const params = {
            ...this.form,
            password: this.passwordEncryption(this.form.password),
            appointRoleId: this.allRoleId.join(","),
          };
          console.log("提交参数", params);
          let res;
          this.editManagerId
            ? (res = await editBusinessAdmin(params))
            : (res = await createBusinessAdmin(params));
          if (res.code) {
            this.$message.success("操作成功");
            this.close();
            this.$parent.initData();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    close() {
      this.dialogFormVisible = false;
      this.$refs["submitForm"].resetFields();
    },
    businessRemoteMethod(query) {
      if (query) {
        this.businessLoading = true;
        setTimeout(async () => {
          this.businessLoading = false;
          this.businessList = await this.getSelectBusiness(query);
        }, 200);
      } else {
        this.businessList = this.baseBusinessList;
      }
    },
    async getSelectBusiness(keyword) {
      const { code, data } = await suggestBusinessList({
        keyword: keyword || "",
      });
      if (code == 200) {
        return data;
      }
      return [];
    },
    onRoleIdChange(item) {
      this.allRoleId = [];
      var roleTypeList = [];
      item.map((val) => {
        roleTypeList.push(val.role_type);
        this.allRoleId.push(val.id);
      });
      this.screenRoleType(roleTypeList);
    },
    screenRoleType(roleTypeList) {
      var allRoleType = Array.from(new Set(roleTypeList));
      if (allRoleType.indexOf(1) != -1) {
        this.showBusiness = true;
        if (!this.myBrand && !this.myComp && this.myShop) {
          this.form.businessId = this.myShop.id;
        }
      } else {
        this.showBusiness = false;
        this.form.businessId = "";
        this.$nextTick(() => {
          this.$refs["submitForm"].clearValidate(["businessId"]);
        });
      }
      if (allRoleType.indexOf(2) != -1) {
        this.showSenior = true;
        this.form.seniorId = this.seniorList.id;
      } else {
        this.showSenior = false;
        this.form.seniorId = "";
        this.$nextTick(() => {
          this.$refs["submitForm"].clearValidate(["seniorId"]);
        });
      }
      if (allRoleType.indexOf(3) != -1) {
        this.showEntity = true;
        this.form.entityId = this.entityList.id;
      } else {
        this.showEntity = false;
        this.form.entityId = "";
        this.$nextTick(() => {
          this.$refs["submitForm"].clearValidate(["entityId"]);
        });
      }
    },
    passwordEncryption(passwordUser) {
      let publicKey =
        "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC1qsjXu6Oblc7W6B/q0+NXUYJ3dN9X4BZ3xncrKJEfCBwS5IE3ZgABQe2MR0fagpfKahtUaeVjLRGJh6A23yyHewxI6kfuAc+/oTxZLJia6K/l46JbLwb96DatpTa9sAk+5MS6IZ94fbaQ8iv9rn+h3Ddo9MDoPW+/sVtWtv9lbwIDAQAB";
      let encryptor = new JSEncrypt();
      encryptor.setPublicKey(publicKey);
      let passwordEncryp = encryptor.encrypt(passwordUser);
      return passwordEncryp;
    },
    handleClose(done) {
      this.close();
      done();
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .accountModal {
  border-radius: 10px !important;
  .el-dialog__header {
    border-bottom: 1px solid #e8eaec;
    .el-dialog__title {
      font-size: 14px;
      color: #17233d;
      font-weight: 700;
    }
  }
  .el-dialog__footer {
    border-top: 1px solid #e8eaec;
  }
}
</style>